html,body {height: 100%;}
body {padding-top: 0px;}
@media (max-width:1198.98px) {body {padding-top: 112px;}}
@media (max-width:767.98px) {body {padding-top: 86px; font-size: .95rem !important;}}
.container-1920 {max-width: 1920px;width: 100%;margin: auto;}
.z-index-2 {z-index:2}
a {color: #606060; text-decoration: underline;}
a:hover {color: #004f9f; text-decoration: none;}

.scroll-to-top {position:fixed !important;bottom:0;right:0 !important;}
.scroll-to-top svg {fill: #fff !important;}
.scroll-to-top {height: auto; width: auto; line-height: normal !important; color: inherit !important; text-align: center;}
.scroll-to-top {color:#fff!important;text-decoration:none !important;background:#2882bc !important;padding:0px;width:40px !important;height:30px !important;line-height:20px !important;border-radius:100px !important;text-align:center !important;bottom:50px !important;border-top-right-radius:0 !important;border-bottom-right-radius:0 !important; border:1px solid #fff}
.scroll-to-top:hover{color:#fff !important;text-decoration:none !important;background:#1e72a9 !important}

/* Sweep To Right */
.btn-primary{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px transparent;position:relative;-webkit-transition-property:color;transition-property:color;-webkit-transition-duration:.3s;transition-duration:.3s;overflow:hidden;background:#004f9f;color:#fff !important; border:0px !important}
.btn-primary:before{content:"";position:absolute;z-index:-1;top:0;left:0;right:0;bottom:0;background:#1a3155;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition-property:transform;transition-property:transform;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out}
.btn-primary:active,.btn-primary:focus,.btn-primary:hover, .researchlink:hover a {background:#004f9f;color:#fff!important}
.btn-primary:active:before,.btn-primary:focus:before,.btn-primary:hover:before, .researchlink:hover a:before {-webkit-transform:scaleX(1);transform:scaleX(1)}

.btn-outline-primary{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px transparent;position:relative;-webkit-transition-property:color;transition-property:color;-webkit-transition-duration:.3s;transition-duration:.3s;overflow:hidden;background:#fff !important;color:#004f9f !important; border:1px solid #004f9f !important}
.btn-outline-primary:before{content:"";position:absolute;z-index:-1;top:0;left:0;right:0;bottom:0;background:#1a3155;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition-property:transform;transition-property:transform;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out}
.btn-outline-primary:active,.btn-outline-primary:focus,.btn-outline-primary:hover {background:#004f9f;border:1px solid #004f9f;  color:#fff!important}
.btn-outline-primary:active:before,.btn-outline-primary:focus:before,.btn-outline-primary:hover:before{-webkit-transform:scaleX(1);transform:scaleX(1)}

.btn-dark{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px transparent;position:relative;-webkit-transition-property:color;transition-property:color;-webkit-transition-duration:.3s;transition-duration:.3s;overflow:hidden;background:rgba(0,0,0,.65);color:#fff; border:1px solid rgba(255,255,255,.75) !important}
.btn-dark:before{content:"";position:absolute;z-index:-1;top:0;left:0;right:0;bottom:0;background:#000;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:0 50%;transform-origin:0 50%;-webkit-transition-property:transform;transition-property:transform;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out}
.btn-dark:active,.btn-dark:focus,.btn-dark:hover{background:rgba(0,0,0,.65);color:#fff!important}
.btn-dark:active:before,.btn-dark:focus:before,.btn-dark:hover:before{-webkit-transform:scaleX(1);transform:scaleX(1)}

/*** Header ***/
.logo img {transition: all ease-in 0.4s;width: auto; height: 86px;}
.bg-header {background: rgba(255, 255, 255, 1);padding: 0rem 0rem; background: #fff;-webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);-moz-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);}
.top-nav-collapse {background: rgba(255, 255, 255, 1) !important;padding: 0rem 0rem; margin:0px; background: #fff;-webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);-moz-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);}
.navbar-nav {font-size: 1rem;}
.navbar-nav .nav-link {color: #000;}
.top-nav {margin-bottom: 1.2rem;} 
.top-nav .nav-item {padding: 0.15rem .65rem 0.15rem !important;}
.top-nav .nav-link {position: relative; overflow: hidden; padding:.55rem 0.3rem .55rem !important; }
.top-bt-nav .nav-item {margin: 0rem 0rem !important;}
.top-bt-nav .nav-link {color: #000; padding: 0.75rem !important; transition: all ease-in 0.4s; font-weight: 500; font-size: 1.05rem;}
.navbar-nav .show > .nav-link {color:#000 !important}
@media (min-width:1200px) {
.dropdown-menu {border-radius: 0px !important; --bs-dropdown-spacer:-0px; border-top:0; border:0; background: #ffffff; border-radius: 0.6rem !important; _-webkit-box-shadow: 3px 3px 15px rgb(0 0 0 / 10%); _-moz-box-shadow: 3px 3px 15px rgb(0 0 0 / 10%); _box-shadow: 3px 3px 15px rgb(0 0 0 / 15%); _transition: height 0.3s ease;}
.dropdown-menu .bg-white {border:1px solid #eee; border-width: 1px 1px 1px 1px;}
}
.dropdown-item:hover {background-color: transparent !important; color:#004f9f; }
.dropdown-item a {display: block; text-decoration: none; color: inherit;}
.bg-search-panel .modal-content {background-color: rgba(0, 0, 0, 0.75);}
.bg-search-panel .modal-header > button {background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;}

.top-bt-nav .dropdown-item a {padding: 0.4rem .5rem; font-weight: 500;}
@media (min-width:1200px) {
  .top-nav .dropdown-menu, .top-bt-nav .dropdown-menu {border-bottom:0rem solid #004f9f;}
  .top-nav .dropdown-item a, .top-bt-nav .dropdown-item a {padding: 0.4rem 1.5rem; font-weight: 500; color:#4090c4; transition: all ease-in .2s;}
  .top-nav .dropdown-item a:hover, .top-bt-nav .dropdown-item a:hover {color:#8a8c8f}
}

@media (min-width: 1200px) {
  .bg-header-home .top-bt-nav .navbar-nav .active .nav-link:before {content:'';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#000;-webkit-transition: .35s;transition: .35s;}
  .bg-header-home .top-bt-nav .nav-link:before, .bg-header-home .top-bt-nav  .nav-link:before {content:'';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#000;-webkit-transition: .35s;transition: .35s;}
  .bg-header-home .top-bt-nav .nav-item:hover .nav-link:before, .bg-header-home .top-bt-nav .nav-item.active .nav-link:before, a.show:before {width: 100%;}

  .top-bt-nav .navbar-nav .active .nav-link:before {content:'';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#1e72a9;-webkit-transition: .35s;transition: .35s;}
  .top-bt-nav .nav-link:before, .top-bt-nav .nav-link:before {content:'';position: absolute;bottom:0px;left: 0;width: 0;-webkit-transform: translateX(0%);transform: translateX(0%);height:3px;background:#1e72a9;-webkit-transition: .35s;transition: .35s;}
  .top-bt-nav .nav-item:hover .nav-link:before, .top-bt-nav .nav-item.active .nav-link:before, a.show:before {width: 100%;}
  .top-bt-nav .active .nav-item .nav-link::before {width: 100%; background: #1e72a9;}
}

@media (min-width:1200px) {
/* .dropdown-menu:before {width: 0;height: 0;border-left: 20px solid transparent;border-right: 20px solid transparent;border-bottom: 20px solid #fff;content: '';position: absolute;z-index: 9;left: 20px;right: auto;margin: auto;top: 0px;} */
}
.top-nav .dropdown-item, .top-bt-nav .dropdown-item {display: block; vertical-align:top;padding:0px;background-color: transparent !important;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-ms-transition: all 0.3s;-o-transition: all 0.3s;transition: all 0.3s; border-bottom:1px solid #eee}
.top-nav .dropdown-item:last-child, .top-bt-nav .dropdown-item:last-child {border:0}
@media (min-width:1200px) {
  .top-nav .dropdown .dropdown-menu, .top-bt-nav .dropdown .dropdown-menu {padding-top:0px; background: transparent!important; z-index: 99; border-radius: 0px !important;}
}

@media (max-width:1200px) {
  .top-bt-nav .dropdown-item {white-space:normal !important; font-size: .85rem;}
}

@media (min-width:1200px) {
.top-nav {margin-top:-.25rem !important; background: #709654; border-bottom-right-radius: 0.5rem!important; border-bottom-left-radius: 0.5rem!important;}
.top-nav li {position: relative;}
.top-nav li:last-child::after{content: none;}
.nav-item {position: relative;}
.top-nav .nav-link {color: #fff}
.top-nav .nav-link:hover {color: #000 !important}
.navbar-nav .nav-link:hover, .navbar-nav .active a, .navbar-nav .active .nav-item a, .open .nav-link {color: #1e72a9}
.top-nav .navbar-nav .nav-link:hover, .top-nav .navbar-nav .active a, .top-nav .navbar-nav .active .nav-item a, .top-nav .open .nav-link {color: #000}
.navbar-nav .active .dropdown-item a {color: #4090c4}
.navbar-nav .active .dropdown-item a:hover {color: #8a8c8f}
ul.top-bt-nav li:nth-child(7) .nav-item .dropdown-menu {left:auto !important; right:0px !important;}
}

@media (min-width:1400px) {
.logo img {transition: all ease-in 0.4s; width: auto;}
}



@media (min-width:1280px) {
  .bg-header-home {background: rgba(0, 0, 0, 0);transition: all ease-in 0.4s;padding: 0rem 0rem;}
.bg-header-inner {background: rgba(255, 255, 255, 1);transition: all ease-in 0.4s;padding: 0rem 0rem;}
.top-nav-collapse {transition: all ease-in 0.4s;}

.bg-header-home .navbar-nav .nav-item .nav-link:hover {color:#fff !important}
.bg-header-home .navbar-nav .nav-link:hover, .bg-header-home .navbar-nav .active a, .bg-header-home .navbar-nav .active .nav-item a, .bg-header-home .open .nav-link {color: #fff}
}

@media (max-width:1198.98px) {
.bg-header-home {background: rgba(255, 255, 255, 1) !important;position:fixed; transition: none !important; -webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2); -moz-box-shadow: 0 3px 5px 0 rgba(0,0,0,.2); box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);}
.top-bt-nav .nav-link {color: #000; border-bottom:1px solid #eee; padding:.7rem 1.5rem !important; }
.top-nav .nav-link {color: #004f9f;}
.top-nav-collapse .navbar-nav .nav-link {color: #000}
.top-nav-collapse .navbar-nav .nav-link:hover {color: #000}
.top-nav-collapse {background: rgba(255, 255, 255, 1);box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);}
.top-nav {margin-top:0rem}
.top-nav li {position: relative;}
.top-nav li::after {content:'|'; position: absolute; color:#fff; top:0px; top: 0px; right:0px;bottom: 0;display: flex;align-items: center;}
.top-nav li:last-child::after{content: none;}
.nav-item {position: relative;}
.nav-item:hover .nav-link:before, .nav-item.active .nav-link:before {width: 100%;}
.top-nav-collapse .navbar-nav .show > .nav-link {color:#000 !important}
.navbar-nav .show > .nav-link {color:#004f9f !important}
.dropdown-menu {border-radius: 0px !important; --bs-dropdown-spacer:-.3px; border: 0px;}
.top-nav .nav-item {margin-top:0}
.top-nav .nav-item button.btn-primary {background:none !important; margin-top:0}
}

@media (max-width:767.98px) {
  .logo img {transition: all ease-in 0.4s; height: 70px; width: auto;}  
  /* .top-nav-collapse .logo img {height: 60px;} */
}
 
/*** Visual ***/
.slider-caption {position: absolute;top: 0;left: 0;right:0;text-align: left;display: flex;align-items: start;justify-content: center;flex-direction: column; z-index: 9;}
.slider-caption .caption-container {width:55%}
.slider-caption .display-6 {opacity: 0; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.slider-caption small {opacity: 0; color: #74c6d4; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.slider-caption a {opacity: 0; -webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}

.active .slider-caption .display-6 {opacity:1; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.active .slider-caption small {opacity:1; color: #74c6d4; --webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}
.active .slider-caption a {opacity:1; -webkit-animation:fadeInLeft .8s ease-in-out;animation:fadeInLeft .8s ease-in-out;}

.carousel-control-next, .carousel-control-prev {display: none;}
.carousel-indicators [data-bs-target] {width: 15px;height: 15px; border-radius: 100% !important;}

.visual-container-1920 {max-width: 1920px;width: 100%;margin: auto;}
@media (max-width:1200px) {
  .visual-container-1920 {max-width: 1920px;width: 100%;margin: auto;margin-top: 0px;}
  .slider-caption small {font-size: 110%;}
}
@media (min-width:1023px) {
  .slider-caption .caption-container {width:50%}
}

@media (max-width:1400px) and (min-width:1280px) {
  .slider-caption .caption-container {width:50%}
  .slider-caption .h1 {font-size: 2.1rem;}
}

@media (min-width:1500px) {
  .slider-caption .caption-container {width:50%}
  .carousel-indicators {bottom:12%}
}

/*** Visual Inside ***/
.bg-visual-inside {background-size: cover; width: 100%;}
.bg-visual-inside img {width: 100%;}
.visual-text {position: absolute; bottom:20%; left:0px; right:0px; color:#fff}

@media (max-width:767.98px){
    .visual-text {position: absolute; bottom:5%; left:0px; right:0px; color:#fff}
}

@media (max-width:1440px){
  .visual-text {position: absolute; bottom:5%; left:0px; right:0px; color:#fff}
}

.bg-visual-inside {background: #2882bc; background-image: url(../../../public/images/bg-visual-inside.png); background-repeat: no-repeat; background-position: bottom; border-bottom:5px solid #53b6ec}
.bg-visual-inside-eve-details {background: #2882bc; background-image: url(../../../public/images/bg-visual-inside.png); background-repeat: no-repeat; background-position: bottom; border-bottom:0px solid #53b6ec; min-height: 220px;}
.bg-visual-inside-eve {background: #2882bc; background-image: url(../../../public/images/bg-events.jpg); background-repeat: no-repeat; background-position: top; border-bottom:0px solid #53b6ec; min-height: 350px;margin-top:118px; background-size: cover;}
/* .event-info {margin-top: -200px;}  */
.event-info {margin-top: -140px;} 


.visual-inside-big{position:relative;z-index:0;background-repeat:no-repeat;background-size:100%;max-width:1920px;width:100%;margin:auto}
.visual-container-inside {margin-top:160px; margin-bottom: -50px;} 
.visual-container-inside-eve-details {margin-bottom: -50px;} 
.visual-container-inside-eve {margin-top:250px; margin-bottom: -50px;} 
.visual-pic {margin-bottom: -50px;} 
@media (max-width:1024px){
.visual-inside-big{position:relative;z-index:1;background-repeat:no-repeat;background-size:100%;max-width:1920px;width:100%;overflow:hidden; margin-bottom: -30px; margin-top:20px;background-size:cover;background-position:center}
}

@media (min-width:1279.98px){
.visual-inside-big-eve{position:relative;z-index:1;background-repeat:no-repeat;background-size:100%;max-width:1920px;width:100%;overflow:hidden; margin-bottom: 0px; margin-top:0px;background-size:cover;background-position:center}
.bg-visual-inside-eve-details {background: #2882bc; background-image: url(../../../public/images/bg-visual-inside.png); background-repeat: no-repeat; background-position: bottom; border-bottom:0px solid #53b6ec; min-height: 280px; margin-top:118px;}
}

.visual-inside img,.visual-inside-big img{max-width:100%;width:100%;height:100%}

.bg-chart1 {background: #2881BB; color:#fff; transition: all ease-in 0.3s;}
.bg-chart2 {background: #2576AC; color:#fff; transition: all ease-in 0.3s;}
.bg-chart3 {background: #216A9B; color:#fff; transition: all ease-in 0.3s;}
.bg-chart4 {background: #1D5E8A; color:#fff; transition: all ease-in 0.3s;}
.bg-chart5 {background: #1d558a; color:#fff; transition: all ease-in 0.3s;}

.arrow-tp {position: absolute; top:50px; right:50px; left: auto;} 
.arrow-tp-right {position: absolute; top:50px; left:0px; right: auto;} 
.arrow-bt {position: absolute; top:0px; right:50px; left: auto;} 
.arrow-bt-right {position: absolute; top:0px; left:0px; right: auto;} 

.focusareas-container {margin-top:-20px; position: relative; z-index: 2;} 
/* .focusareas-container .row {border-radius: .75rem; overflow: hidden;} */

@media (min-width:768px){
.focusareas-container {margin-top:-40px; position: relative; z-index: 2;} 
}

@media (min-width:1280px){
.focusareas-container {margin-top:-55px; position: relative; z-index: 2;} 
}
.bg-focusareas1 {background: #2881BB; color:#fff; transition: all ease-in 0.3s;}
.bg-focusareas2 {background: #2576AC; color:#fff; transition: all ease-in 0.3s;}
.bg-focusareas3 {background: #216A9B; color:#fff; transition: all ease-in 0.3s;}
.bg-focusareas4 {background: #1D5E8A; color:#fff; transition: all ease-in 0.3s;}
.bg-focusareas5 {background: #1d558a; color:#fff; transition: all ease-in 0.3s;}

.bg-focusareas6 {background: #003c75; color:#fff; transition: all ease-in 0.3s;}

.bg-focusareas1:hover,.bg-focusareas2:hover,.bg-focusareas3:hover, .bg-focusareas4:hover, .bg-focusareas5:hover, .bg-focusareas6:hover {background: #709654; color:#fff;}

.focusareas-container a img {opacity:.80 !important; transition: all ease-in 0.3s;}
.focusareas-container a:hover img {opacity:1 !important;}
.focusareas-container a {color: inherit; opacity: .80; text-decoration: none; transition: all ease-in 0.3s;}
.focusareas-container a:hover {color: inherit; opacity: 1; text-decoration: none;}

.border-10 {border-width:10px !important;}

@media (min-width:767.98px){
.professor-message {margin-left:-9%}
.pic-professor {margin-top:-120px} 
.container-end {margin-left:-9%}
.container-start {margin-right:-9%}
}

/*** Ticker ***/
.bg-ticker {background: #ebebeb;}

/*** Border Box ***/
.border-box {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-1 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-1::before {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; left:0rem; right:0rem; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-inside-rht {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-inside-rht::before {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}

.border-box-inside-lft {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
.border-box-inside-lft::after {content: ''; border:0px solid #004f9f; border-width: 0px 0px 0px 0px; width: 100%; position: absolute; top:0px; bottom:0px; height:100%; right:0}

@media (min-width:768px){
  .border-box-1 {border:0px solid #004f9f; position: relative;}
  .border-box-1::before {content: ''; border:1px solid #004f9f; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}
  .border-box-2 {border:0px solid #004f9f; position: relative;}
  .border-box-2::before {content: ''; border:1px solid #004f9f; width: 100%; position: absolute; top:0px; bottom:0px; height:100%;}  
}

@media (min-width:992.98px){
  .border-box::before {width: 125%;}
  .border-box-inside-rht::before {width: 165%;}
  .border-box-inside-lft::after {width: 165%;}

  .border-box-1 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
  .border-box-1::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; width: 94%; position: absolute; top:0px; bottom:0px; height:100%;}
  
  .border-box-2 {border:0px solid #004f9f; _border-width: 1px 0px 1px 1px; position: relative;}
  .border-box-2::before {content: ''; border:1px solid #004f9f; border-width: 1px 1px 1px 1px; right:0px; width: 94%; position: absolute; top:0px; bottom:0px; height:100%;}
}

@media (min-width:1198.98px){
.border-box::before {width: 125%;}
.border-box-inside-rht::before {width: 144%;}
.border-box-inside-lft::after {width: 144%;}
}

/*** Header ***/
.hd {position: relative; color:#000;display:flex;align-items: center;}
.hd span, .hd1 span {color:#8a8c8f}
.hd1 {position: relative; color:#000;display:flex;align-items: center;justify-content: center;}
.heading span {color:#8a8c8f}

/*** Stats ***/
.stats ul {display: flex; justify-content: center;}
.stats ul li {border-right:1px solid #aaa; padding: 0px 5.3%; display: inline;}
.stats ul li:first-child {padding: 0px 5.3% 0px 0; }
.stats ul li:last-child {border-right:0px solid #3a4046; padding: 0px 0px 0px 5.3%;}

@media (max-width:992px) {
.stats ul {display: block; justify-content: center;}
.stats ul li {display: inline-block; width: 50%;; margin-bottom: 10px;}
.stats ul li:first-child {padding: 0px 5.3% 20px 5.3%; }
.stats ul li:nth-child(2) {border-right:0px solid #3a4046;}
.stats ul li:last-child {border-right:0px solid #3a4046; padding: 0px 0px 0px 5.3%;}
}

/*** News ***/
.news .pic {min-width:230px;}
.news .btnlink {min-width:30px; color:#fff; font-size: 1.8rem; transition: all ease-in 0.3s; }
.news .btnlink:hover {background-color: #000 !important;}
.news h5:hover  {color:#004f9f} 


/*** Events ***/
.events .dates {min-width:135px; background: #004f9f; margin-left: -1rem; padding: 1rem; text-align: center; color:rgba(255,255,255,.85); transition: all ease-in 0.3s;}
.events .dates h2 {line-height: normal;}
.events .btn-link {color:#3a4046; text-decoration: none;}

.events a { transition: all ease-in 0.3s; text-decoration: none;}
.events:hover a.border {border:1px solid #000 !important}
@media (max-width:767.98px) {
  .events .dates {min-width:135px; background: #004f9f; margin-left: 0rem; padding:1.2rem 1.5rem; text-align: center; color:rgba(255,255,255,.85); transition: all ease-in 0.3s;}
  .events:hover .dates {min-width:135px; background: #000; margin-left: 0rem; text-align: center; color:rgba(255,255,255,.85)}
}

/*** Parallax ***/
.react-parallax-bgimage {background-size: cover; background-position: left;}

/*** Research Areas ***/
.btn-5 {
    display: inline-block;
    position: relative;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 20px 30px;
  }
  @media (max-width:1279px) {
    .btn-5 {
      display: inline-block;
      position: relative;
      background: none;
      border: none;

      cursor: pointer;
      padding: 20px 10px;
    }
  }
  figure {
    display: block;
    padding: 20px 20px;
    margin: 0px;
  }
  .btn-5::before,
  .btn-5::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #004f9f;
}

figure::before,
figure::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #004f9f;
}

@media (min-width:1279px) {
.btn-5:hover::before,
.btn-5:hover::after {
  width: 100%;
}
.btn-5:hover figure::before,
.btn-5:hover figure::after {
  height: 100%;
}
.btn-5::after {
    left: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
  .btn-5 figure::after {
    right: 0;
    top: 0;
    transition-duration: 0.4s;
  }
  .btn-5::before {
    right: 0;
    top: 0;
    transition-duration: 0.4s;
  }
  .btn-5 figure::before {
    left: 0;
    bottom: 0;
    transition-duration: 0.4s;
  }
}

/*** Academic Programs ***/
.academics-info {padding-left:120px;}
.academics-info .list-group-item {background: transparent; padding: 0.858rem 0; margin: 0rem; border-bottom: 1px solid #909090;}

.academics-info a {color:inherit; text-decoration: none; transition-duration: 0.4s;}
.academics-info a:hover {color:#004f9f; text-decoration: none;}

@media (max-width:1279px){
.bg-academics {background-image: none; background: #dddbd0;}
.academics-info {padding-left:0px;}
}

@media (max-width:767.98px){
.academics-info ul li {font-size: 1rem;} 
}

/*** Achievements ***/
@media (max-width:1199.98px){
  .bg-achievements {background-image: none; background: #f6efdb; min-height: auto;}
  .academics-info {padding-left:0px;}
}

.sp-caption-container {padding: 0px; margin: 0px;}
.sp-buttons {position: absolute;width: 100%;text-align: center;padding-top: 10px;bottom: 10px;}
.sp-layer {max-width: 1400px;margin: 0 auto;left: 0px;right: 0px;}


.list {display:block;}
/* .list li {padding:0px; margin:0px 0px 15px 40px;} */

.list ul {display: block;margin: 0;padding: 0;margin-bottom: 20px}
.list ul li {list-style: none;margin: 0;padding: 0 10px 5px;width: 100%;padding-left: 25px;-webkit-animation-name: fadeInUp;animation-name: fadeInUp}
.list ul li ul li {list-style: none;margin: 0 0 0 0px;padding: 0 5px 5px;width: 100%;padding-left: 25px;-webkit-animation-name: fadeInUp;animation-name: fadeInUp; overflow: hidden;}
.list ul li::before {content: "\25AA";color: #709654;display: inline-block;width: 1rem;margin-left: -1rem; font-size: 1.65rem; line-height:1; position: absolute;}
.list ul li ul li::before {content: "\2022";color: #709654;font-weight: bold;display: inline-block;width: 1em;margin-left: -1em;}
.list ul li a,table a {color: #709654;text-decoration: underline!important}
.list ul li a:hover,table a:hover {color: #709654;text-decoration: none!important}

.list-white ul {display: block;margin: 0;padding: 0;margin-bottom: 20px}
.list-white ul li {list-style: none;margin: 0;padding: 0 10px 5px;width: 100%;padding-left: 25px;-webkit-animation-name: fadeInUp;animation-name: fadeInUp}
.list-white ul li ul li {list-style: none;margin: 0 0 0 0px;padding: 0 5px 5px;width: 100%;padding-left: 25px;-webkit-animation-name: fadeInUp;animation-name: fadeInUp; overflow: hidden;}
.list-white ul li::before {content: "\25AA";color: #fff;display: inline-block;width: 1rem;margin-left: -1rem; font-size: 1.65rem; line-height:1; position: absolute;}
.list-white ul li ul li::before {content: "\2022";color: #fff;font-weight: bold;display: inline-block;width: 1em;margin-left: -1em;}
.list-white ul li a,table a {color: #709654;text-decoration: underline!important}
.list-white ul li a:hover,table a:hover {color: #ddd;text-decoration: none!important}

.list ol {display: block;margin: 0;padding: 0;margin-bottom: 20px}
.list ol li {list-style: decimal;margin: 0 0 0 25px;padding: 0 3px 5px;width: 100%;-webkit-animation-name: fadeInUp;animation-name: fadeInUp}

/*** Breadcrumb ***/
.breadcrumb-panel {display: flex; justify-content: center; padding-top: 1rem; border-top:1px solid #dee2e6}

/*** Footer ***/
footer {background: #ebebeb; color: #000; font-size: .95rem!important;}
footer .list-group-item {background: transparent;color: #000;padding: .2rem 0px;border: none}

footer a {color: inherit; text-decoration: none;}
footer a:hover {color: inherit; text-decoration: underline;}

.footer-links ul li a{padding:0 6px!important}
.footer-links ul li:after{content:'|';font-size:9pt;position:relative;top:-3px;right:0;display:inline-block;vertical-align:top;color:#505050!important;margin:5px 0}
.footer-links li:last-child:after{display:none!important}

@media (min-width:1200px){
  .footer-links ul li.foot-home:after{display:none!important}  
}

/* @media (max-width:575.98px){
#address h5 {display: none;}
} */


/*** Social Media ***/
.falinked {width: 40px; height: 40px; transition: all ease-in-out 0.3s;}
.falinked:hover {background: #0275b5;color: #fff!important; border:1px solid #0275b5}
.icon-16 {width: 16px; height: auto;}
.icon-18 {width: 18px; height: auto;}
.icon-20 {width: 20px; height: auto;}
.icon-24 {width: 24px; height: auto;}
.icon-75 {width: 75px; height: auto;}
.icon-100 {width: 100px; height: auto;}
.icon-120 {width: 100px; height: 100px;}
.icon-130 {width: 125px; height: 125px;}
.icon-150 {width: 150px; height: 150px;}
.icon-w30 {width: 30px;}
.Faicon14 svg {width: 10px; height: 13px; padding-bottom: 3px;}
.Faicon svg {width: 15px; height: 15px;}
.icon-search svg {width: 20px; height: 20px;}
ul.social_media{margin:0px;padding:5px 0px;width:100%;}
ul.social_media li{display:inline-block;list-style-type:none;list-style-image:none;padding:0px;margin:0 10px 0px 0px;border:none;vertical-align:top;}
ul.social_media li a{display:block;transition:none .6s ease-in-out;-webkit-transition:none .3s ease-in-out;-moz-transition:none .3s ease-in-out;-o-transition:none .3s ease-in-out;-ms-transition:none .3s ease-in-out; color:#1d4d27;}
ul.social_media li a:hover{color:#fff}
.facebook, .google, .linkedin, .instagram, .twitter, .youtube,.whatsapp,.pinterest,.blog {transition:all .3s ease-out;-webkit-transition:all .3s ease-out;-moz-transition:all .3s ease-out;-o-transition:all .3s ease-out;-ms-transition:all .3s ease-out;width:40px;height:40px;line-height:40px;text-align:center; border-radius: 100%; background:#fff;}
.facebook:hover{background:#254fa7 !important;}
.twitter:hover{background:#0f1419 !important;}
.linkedin:hover{background:#0069d6 !important;}
.instagram:hover{background:#3e739d !important;}
.youtube:hover{background:#ff0000 !important;}
.whatsapp:hover{background: #59CE72 !important;}
.pinterest:hover{background: #E60023 !important;}
.blog:hover{background: #ff4f00 !important;}

.focus-icon-75 {max-width: 75px;}

@media (max-width:1199px) {
ul.social_media .fa{padding:0px;margin:0px;font-weight:300;color:#fff;width:50px;height:50px;line-height:53px;text-align:center; font-size:25px;}
}

/*** Offcanvas ***/
.offcanvas-toggle2{display:none;}
.offcanvas-toggle{padding:0px;background:transparent;border:0px;border-radius:0px;display:none;cursor:pointer;outline:none!important;}
.offcanvas-toggle .icon-bar{background:#38964f;display:block;width:22px;height:2px;border-radius:1px;transition:all .25s ease-in-out}
.offcanvas-toggle .icon-bar + .icon-bar{margin-top:4px;}
.offcanvas-toggle .icon-bar:nth-child(3){width:12px;}
.offcanvas-toggle:hover .icon-bar:nth-child(3){width:22px;}
.offcanvas-toggle.is-open .icon-bar:nth-child(1){-webkit-transform:rotate(45deg) translate(5px, 4px);transform:rotate(45deg) translate(5px, 4px)}.offcanvas-toggle.is-open .icon-bar:nth-child(2){opacity:0}.offcanvas-toggle.is-open .icon-bar:nth-child(3){width:22px;-webkit-transform:rotate(-45deg) translate(4px, -4px);transform:rotate(-45deg) translate(4px, -4px)}
div#offcanvas-overlay{position:fixed;display:none;height:100%;width:100%;top:0;left:0;cursor:pointer;background-color:rgba(0,0,0,.7);visibility:hidden;opacity:0;z-index:9;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transition:opacity .4s 0s,visibility 0s .4s;-moz-transition:opacity .4s 0s,visibility 0s .4s;transition:opacity .4s 0s,visibility 0s .4s}
.open-menu div#offcanvas-overlay{opacity:1;display:block;visibility:visible;-webkit-transition:opacity .4s 0s,visibility 0s 0s;-moz-transition:opacity .4s 0s,visibility 0s 0s;transition:opacity .4s 0s,visibility 0s 0s}
@media (max-width:1199.98px){
.offcanvas-toggle, .offcanvas-toggle2{display:inline-block}
.offcanvas-collapse, .offcanvas-collapse2{position:fixed;top:0;bottom:0;left:-250px;max-width:250px;width:100%;padding-right:0;padding-left:0;overflow-y:auto;visibility:hidden;background-color:#000;transition-timing-function:ease-in-out;transition-duration:.3s;transition-property:left, visibility;z-index:9999;}
.offcanvas-collapse.open, .offcanvas-collapse2.open{left:0;visibility:visible;}
.offcanvas-toggle2 {cursor: pointer;box-shadow: 2px 2px 0px rgba(0,0,0,0.8);width:100%;margin-top:-15px;margin-bottom:30px}
}

@media (min-width:1198.98px){
.shiny .bg-pic{overflow:hidden;display:inline-block;position:relative;margin-bottom:.5rem;}
.shiny .bg-pic:after{content:'';position:absolute;top:0;left:-20%;width:0;height:100%;background-color:rgba(255,255,255,0.4);-webkit-transform:skew(20deg);transform:skew(20deg);}
.shiny:hover img{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);-moz-transform:scale(1.1);transform:scale(1.1);}
.shiny img, .shiny:hover .bg-pic:after{-webkit-transition:all 0.8s ease-in-out;-moz-transition:all 0.8s ease-in-out;transition:all 0.8s ease-in-out;}
.shiny:hover .bg-pic:after{width:150%;background-color:rgba(255,255,255,0);}
}

.admission-update-list ul {border-radius: 0px; margin-bottom: 1.5rem;}
.admission-update-list ul li {border-width: 1px 1px 1px 1px !important;}
.admission-update-list ul li:nth-child(1) {min-width: 120px; font-weight: 600;}
.admission-update-list ul li:nth-child(2) {width: auto; }
.admission-update-list ul li:nth-child(3) {min-width: 210px;}

@media (max-width:767px) {
.admission-update-list ul li {border-width: 1px 1px 1px 1px !important;}
.admission-update-list ul li:nth-child(1) {min-width: 120px; border-bottom:0px; font-weight: 600;}
.admission-update-list ul li:nth-child(2) {width: auto; border-bottom:0px; border-top:0px;}
.admission-update-list ul li:nth-child(3) {min-width: 210px; border-top:0px;}
}

@media (min-width:768px) {
.admission-update-list ul {border-radius: 0px;  margin-bottom: 0rem;}   
.admission-update-list ul li {border-width: 0px 0px 1px 0px !important; padding: .75rem 0rem;}
.admission-update-list ul li:nth-child(2) {width: auto; padding-right: 2rem; padding-left: 2rem;}
}

@media (max-width:767px) {
  .publications-table table thead tr th:nth-child(1) {min-width: 150px !important;}
  .publications-table table thead tr th:nth-child(2) {min-width: 200px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}    
}

@media (min-width:768px) {
  .publications-table table thead tr th:nth-child(1) {min-width: 230px !important;}
  .publications-table table thead tr th:nth-child(2) {min-width: 200px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}
  .publications-table table thead tr th:nth-child(4) {min-width: 85px !important;}
  .publications-table table tbody tr td:nth-child(4) {text-align: center;}
}

@media (min-width:1024px) {
  .publications-table table thead tr th:nth-child(1) {min-width: 230px !important;}
  .publications-table table thead tr th:nth-child(2) {min-width: 400px !important;}
  .publications-table table thead tr th:nth-child(3) {min-width: auto !important;}
  .publications-table table thead tr th:nth-child(4) {min-width: 85px !important;}
  .publications-table table tbody tr td:nth-child(4) {text-align: center;}
}

.publication-content table tr td, .publication-content table tr th {font-size: 1rem !important;}
.publication a {word-break: break-all;}

.research a {color:#004f9f}
.research:hover a {color:inherit}

.research div img {scale: 1; -webkit-transition: all 5s linear; -moz-transition: all 5s linear; -o-transition: all 5s linear; -ms-transition: all 5s linear;}
.research div:hover img {scale: 1.3;}

.research div {-webkit-transition: all .3s linear; -moz-transition: all .3s linear; -o-transition: all .3s linear; -ms-transition: all .3s linear;}
.research div:hover {--bs-bg-opacity: .3}

.projects .col:last-child hr {display: none;}

@media (max-width:1200px) {.player-wrapper {margin-top: 0px;}}
.player-wrapper {position: relative;top:0px;height: 0px;left:0px;right:0px;min-height: 100%;margin-top: -118px;overflow: hidden;}
.react-player {position: absolute;height: 108%;width: 100%;}
.bg-video { position: absolute; z-index: 2;}



.icon-circle{width:130px;height:130px;border-radius:100%;text-align:center}
.icon-circle-160{width:160px;height:160px;border-radius:100%;text-align:center}
.icon-mt-n60{margin-top:-60px!important}.icon-mt-n80{margin-top:-80px!important}

.slick-list,.slick-slider,.slick-track{position:relative;display:block}
.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}
.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}
.slick-list{overflow:hidden;margin:0;padding:0}
.slick-list:focus{outline:0}
.slick-list.dragging{cursor:pointer;cursor:hand}
.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.slick-track{top:0;left:0}
.slick-track:after,.slick-track:before{display:table;content:''}
.slick-track:after{clear:both}
.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl]
.slick-slide{float:right}.slick-slide img{display:block}
.slick-slide.slick-loading img{display:none}
.slick-slide.dragging img{pointer-events:none}
.slick-initialized .slick-slide{display:block}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}
.slick-arrow.slick-hidden{display:none}


.slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}
.slick-dots li button:before,.slick-next:before,.slick-prev:before{font-family:slick;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:20px;height:20px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);cursor:pointer;color:transparent;border:none;outline:0;background:0 0}
.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{color:transparent;outline:0;background:0 0}
.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}
.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}
.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;color:#fff}
.slick-prev{left:-25px}[dir=rtl] .slick-prev{right:-25px;left:auto}
.slick-prev:before{content:'←'}
.slick-next:before,[dir=rtl] 
.slick-prev:before{content:'→'}
.slick-next{right:-25px}[dir=rtl] 
.slick-next{right:auto;left:-25px}[dir=rtl]
.slick-next:before{content:'←'}
.slick-dotted.slick-slider{margin-bottom:30px}
.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}
.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 2px;padding:0;cursor:pointer}
.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0}
.slick-dots li button:focus,.slick-dots li button:hover{outline:0}
.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}
.slick-dots li button:before {position: absolute;top: 0;left: auto;right: auto;width: 12px;height: 12px;background: #ebebeb;content: "";border-radius: 50%;text-align: center}
.slick-dots li.slick-active button:before {background: #15519d;border: 0}

/* .careers a {text-decoration: none;} */
.careers a.btn {text-decoration: none;}

#v-pills-tab .nav-link {text-align: start; border-top: 1px solid #87be92; border-bottom: 1px solid #87be92; color: #666; -webkit-transition: all 300ms linear; -moz-transition: all 300ms linear; -o-transition: all 300ms linear; -ms-transition: all 300ms linear; font-size: 0.9375rem !important; padding:8px 11px;_padding-bottom: 0.25rem;}
#v-pills-tab .nav-link:hover {color: #1d4d27;}
#v-pills-tab .nav-link:first-child {border-top: 0px solid #eee;}
/* #v-pills-tab .nav-link:last-child {border-bottom: 0px solid #eee;} */
#v-pills-tab .nav-sublink {border-top: 0px !important; padding-top: .25rem !important; padding-bottom: .25rem !important; display: block; padding-left: 2rem !important;}
#v-pills-tab .nav-sublink::before {content:''; background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E" ); width: 8px; height: 11px; background-repeat: no-repeat; display: inline-block; margin-left: -1rem; padding-left: .75rem;}
#v-pills-tab .nav-sublink:hover {padding-left: 1.5rem; }
#v-pills-tab .nav-sublink.active {padding-left: 2.5rem !important; }
#v-pills-tab .nav-sublink:last-child{border-top: 0px solid #eee !important;}
#v-pills-tab .active {_background: #87be92 !important; color: #1d4d27 !important; font-weight: normal;}
/* #v-pills-tab #v-pills-tab > .active {padding-left: 2.5rem; background: #eee !important;}.active {padding-left: 2.5rem; background: #eee !important;} */
/* #v-pills-tab .nav-sublink > .active::before {content:''; background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E" ); width: 8px; height: 11px; background-repeat: no-repeat; display: inline-block; margin-left: -1rem; padding-left: 1rem; } */
#v-pills-tab .btn-close {background: none; width: auto !important; height: auto !important;}

.accordion-item {border-radius: .75rem !important; border: 1px solid #dee2e6 !important;}
.accordion {
  --bs-accordion-color: hsl(210, 11%, 15%);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #709654;
  --bs-accordion-active-bg: #e7f1ff;
}
.accordion-button {background-color: transparent !important;}
.accordion-button:focus {box-shadow: none;}
.accordion-button:not(.collapsed) {background:#709654 !important; color: #fff; box-shadow: none;}
.accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-body {background: #f0f4ee;}
@media (max-width: 991px) {
  /* .tab-content>.tab-pane {display: block;opacity: 1;} */
  .tab-content>.tab-pane {opacity: 1;}
  .accordion {border-radius: .375rem; overflow: hidden; border: 1px solid #dee2e6 !important;}
  .accordion-button {background-color: #f5f5f5;}
  .accordion-item {border-radius: 0rem !important; border: 1px solid #dee2e6 !important; border-width:1px 0px 0px 0px !important; margin-bottom: 0rem; overflow: hidden;}
  .accordion-item:first-child {border-radius: 0rem !important; border: 1px solid #dee2e6 !important; border-width:0px 0px 0px 0px !important;}
  .accordion-item:last-child {border-radius: 0rem !important; border: 1px solid #dee2e6 !important; border-width:1px 0px 0px 0px !important;}
  .accordion-button:not(.collapsed) {color: #fff;background-color: #004f9f;}
  .accordion-button:not(.collapsed)::after {background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");}
}

.people .col {border-bottom:1px solid rgba(0,0,0,0.175); padding-bottom: 3rem;}
.people .col:last-child {border-bottom:0px solid rgba(0,0,0,0.75); padding-bottom: 0rem;}

.people a {text-decoration: none;}

.icon-circle{width:130px;height:130px;border-radius:100%;text-align:center}
.icon-circle-160{width:160px;height:160px;border-radius:100%;text-align:center}
.icon-circle-110 {width: 110px;height: 110px; border-radius:100%;text-align:center}
.icon-mt-n80 {margin-top: -80px!important;}

.popup .modal-header {height: 0px; min-height: 0;}
.popup .modal-header button, .popup-students .modal-header button {position: absolute; right:-.25rem; top:-.25rem; border:2px solid #fff; background: #000; border-radius: 100%; z-index: 99; opacity: 1; width: 18px; height: 18px;}
.popup .modal-header .btn-close, .popup-students .modal-header .btn-close {background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") !important; background-size: 40%; background-repeat: no-repeat; background-position: center center;  opacity: 1;}
.popup .modal-content, .popup-students .modal-content {overflow:visible !important;}
.popup .modal-dialog-scrollable .modal-body {overflow-y: auto;margin-top: -2rem;}
.popup a:hover {color: #429854;}

.popup-students .modal-header {height: auto; min-height: auto;}

.left-navbar {display: block; padding: 0; margin: 0;}
.left-navbar li {display: block; padding: 0; margin: 0; font-size: 0.9375rem !important;}
.left-navbar li a {padding: 8px 11px; display: block; border-bottom: 1px solid #87be92; -webkit-transition: all 300ms linear;-moz-transition: all 300ms linear;-o-transition: all 300ms linear;-ms-transition: all 300ms linear; text-decoration: none;}
.left-navbar li a:hover {padding: 8px 11px; color: #1d4d27; text-decoration: none;}
.left-navbar li a.active {padding-left: 1rem; color: #1d4d27; _background:#87be92 !important; font-weight: bold;}
/* .left-navbar li:last-child a {border:0px;} */

.btn-link {font-weight: 400;color: #01509f !important;text-decoration: underline;}

#v-pills-tab .nav-link {text-align: start; border-top: 1px solid #eee; border-bottom: 0px solid #eee; color: #666; -webkit-transition: all 300ms linear; -moz-transition: all 300ms linear; -o-transition: all 300ms linear; -ms-transition: all 300ms linear; font-size: 0.9375rem !important; padding:8px 11px;padding-bottom: 0.25rem;}
#v-pills-tab .nav-link:hover {color: #429854;}
#v-pills-tab .nav-link:first-child {border-top: 0px solid #eee;}
#v-pills-tab .nav-link:last-child {border-bottom: 1px solid #eee;}
#v-pills-tab .nav-sublink {border-top: 0px !important; padding-top: .25rem !important; padding-bottom: .25rem !important; display: block; padding-left: 2rem !important;}
#v-pills-tab .nav-sublink::before {content:''; background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E" ); width: 8px; height: 11px; background-repeat: no-repeat; display: inline-block; margin-left: -1rem; padding-left: .75rem;}
#v-pills-tab .nav-sublink:hover {padding-left: 1.5rem; }
#v-pills-tab .nav-sublink.active {padding-left: 2.5rem !important; }
#v-pills-tab .nav-sublink:last-child{border-top: 0px solid #eee !important;}
/* #v-pills-tab .active {padding-left: 2.5rem; background: #eee !important; padding-left: 1rem; color: #000 !important;} */
/* #v-pills-tab #v-pills-tab > .active {padding-left: 2.5rem; background: #eee !important;}.active {padding-left: 2.5rem; background: #eee !important;} */
/* #v-pills-tab .nav-sublink > .active::before {content:''; background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E" ); width: 8px; height: 11px; background-repeat: no-repeat; display: inline-block; margin-left: -1rem; padding-left: 1rem; } */
#v-pills-tab .btn-close {background: none; width: auto !important; height: auto !important;}

#v-pills-tab .nav-sublink a {text-decoration: none;}
#v-pills-tab .nav-sublink a:hover {color: #1d4d27 !important;}

@media (max-width: 767px) {
.left-navbar li a {color:#000; padding: 8px 12px; }
.left-navbar li a:hover {padding: 8px 12px; color: #0854a1;}
.left-navbar li a.active {padding: 8px 12px; color: #000; font-weight: 700; background: transparent !important;}
}

.publications .col {border-bottom: 1px solid #eee; border-radius: 0 !important; padding-top: 1.25rem; padding-bottom: 1.25rem;} 


.parallax-banner-info {min-height: 450px;}
.parallax-banner-info a, .research a, .academics a {text-decoration: none;}
.projects a {color: #429854;}

@media (max-width: 991.98px) {
  .parallax-banner-info {min-height: 250px;}
}

.speaker a { text-decoration: none;}

.cookie-wrapper {
  background-color: #333131 !important;
  padding: 0.5rem;
  font-size: 1rem;
  z-index: 1;
}

.cookie-wrapper .btn {
  background-color: #004f9f !important;
  color: #fff !important;
  font-size: .875rem !important;
  padding: 0.25rem 1rem !important;
}
.cookie-wrapper .btn:hover {
  background: #fff !important;
  color: #000 !important;
}

@media (max-width:767.98px) {
  .cookie-content {flex: auto !important; font-size: 80% !important;}
  }

  .bg-dark-green {background: #12391a; opacity: .90;}

  .show-more-less-clickable {display: inline-block !important;}

  .bg-focus-area {background: #327700;}


  /* BLOG */
.blog-right {min-height: 20px; margin-bottom: 20px;padding:10px;}
.blog-right h3 {color:#709430; text-transform:uppercase}
.tm-mng-bx {background: #fff;padding: 15px;border-radius:0.5rem!important; margin: 0 10px;-webkit-box-shadow: 0 3px 10px -1px rgb(0 0 0 / 20%);-moz-box-shadow: 0 3px 10px -1px rgba(0,0,0,.2);box-shadow: 0 0px 8px -1px rgb(0 0 0 / 15%);}
.blog-right {background: #f1f1f1 !important;}

/* BLOG LEFT PANEL */
ul.blog-list { padding:0; margin:0; }
ul.blog-list > li { padding:0 0 20px; margin:0 0 40px; list-style:none; border-bottom: 1px solid #dee2e6;width:100%; }
ul.blog-list > li:last-child { background:none;border-bottom: 0px;margin:0; padding:0 } 
ul.blog-list > li p > a{color: #646464;text-decoration: none;}
.blog-box h2, .blog-box h2 a {font-size:1.5rem !important;margin-bottom:0px;text-decoration: none;color: #cf2025;}


.blog-box   {background:#FFF;}
.blog-box img         { max-width:100%; _width:100%;  height:auto!important; padding:0px; margin:0px auto;}
.blog-box .postedby   { width:80%; margin:0px; padding:0px; color:#868686; float:left; font-size: 10pt;}
.blog-box .backbtn   {  width:15%; float:right; text-align:right; color:#009beb; }

 

.blog-box .readmore       {margin:0px auto; padding:10px 3px 0px 0px; margin:0px; _float:left; text-transform:none; _font-size:14px; color:#00b3c3;}
.blog-box .readmore img   {border:none}

.blog-box .readmore a     { color:#009fad; font-size:11pt; text-decoration:none; white-space:nowrap;}
.blog-box .readmore a:hover    { color:#000;  text-decoration:underline;}
.blog-box .readmore a:hover fa { color: #000;}
.blog-box .readmore a fa{color:#aa1b0d;  text-decoration:none;}

.blog-tags {width:auto; padding:0px 0px; margin:0px; }

.tags {width:auto; margin:0px; padding:0px; float:left; position:relative; padding-left:25px; margin-right:4px; font-size:11pt;color: #404040;  }
.tags svg, .tags .fa { color: #404040; margin-left: -22px;margin-right:3px;   }

ul.tag-list {list-style:none; padding:0px; margin:0px; font-size:11pt;color: #136db9;}
ul.tag-list li {list-style:none; margin:0px 4px 0px 0px; display:inline-block;} 

ul.tag-list li a        {text-decoration:none; position:relative; color:inherit; padding:0px 0px 0px 0px;   display:block;}
ul.tag-list li a:after{content:","; position:relative; right:0px;}
ul.tag-list li:last-child a:after{display:none}
ul.tag-list li a:hover {color:#01509f}
/*ul.tag-list li:first-child a {padding-left:7px !important}*/
.blog-networking {width:auto; padding:0px; margin:0px;}
.blog-details .blog a{ /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;}

.blog h2, .blog h2 a{ color:#01509f !important}
.blog h2 a:hover{color:#da291c !important}
@media (max-width:767px){
.blog .blog-pic{ width:auto !important; max-width:225px; display:table; float:none; margin-right: auto !important; margin-bottom:20px;}
.blog img{ float:none!important; margin:0 auto!important;}
.blog h2, .blog h2 a{font-size: 13pt !important; margin-bottom:10px!important; margin-top:5px !important}
ul.media-links.network-list li:first-child{display:block;}
.blog .forms h6 + p{font-size:14px; }
}
 
.links ul.links-list {list-style:none; padding:0px; margin:0px 0px;}
.links ul.links-list li {list-style:none; padding:0px 25px 5px 25px; position:relative; margin:0px; display:inline-block; vertical-align:middle;}
.links ul.links-list li .fa, .links ul.links-list li svg{margin-left: -22px;margin-right:3px;}

/*new*/
ul.recent {list-style: none;padding: 0;margin: 0;text-transform: uppercase;margin-bottom: 0 }
ul.recent > li {padding: 10px 0;border-bottom: #ccc 1px solid;margin: 0 8px;color: #000 }
ul.recent li a {color: #000 ;text-decoration: none;}
ul.recent li a:hover {color: #01509f }
ul.recent li ul {list-style: none;padding: 0 0;margin: 0;text-transform: none }
ul.recent li ul li {padding:3px 0;margin: 0 0 0 20px;list-style-type: disc; line-height: 15pt !important;color: #979797;line-height: normal }
.navigation ul.recent:last-child li {border-bottom: 0 }
ul.recent li ul li a {color: #666;text-decoration: none;font-size: 10.2pt;padding:0;display: inline-block;vertical-align: top }
ul.recent li ul li a:hover {color: #474747;text-decoration: underline }
ul.media-links {margin: 5px 0 0 !important }
ul.media-links li {margin: 0 3px 10px !important }
ul.media-links li a:first-child {border: #606060 1px solid;color: #606060;width: 47px;line-height: 30px;text-align: center;border-radius: 20px;-webkit-transition: all .3s ease 0s;-o-transition: all .3s ease 0s;transition: all .3s ease 0s;display: block }
ul.media-links li a .fa {color: #606060 !important }
ul.media-links li a:hover .fa {color: #fff !important }
ul.media-links li a:hover {background: #606060;border-color: #606060;color: #fff }
ul.media-links li a.btn-link {width: auto !important;padding: 0 10px;text-transform: uppercase;font-size: 10.5pt;border-radius: 50px !important;text-decoration: none }
ul.media-links .fa-envelope, ul.media-links .fa-facebook, ul.media-links .fa-instagram, ul.media-links .fa-linkedin, ul.media-links .fa-pinterest, ul.media-links .fa-twitter, ul.media-links .fa-whatsapp, ul.media-links .fa-youtube{height:auto !important; width:auto !important; line-height:1 !important}
ul.media-links li a:hover .fa {background:none !important}
ul.media-links .fa-whatsapp{font-weight:bold !important; }
ul.media-links .fa-envelope:hover, ul.media-links .fa-facebook:hover, ul.media-links .fa-instagram:hover, ul.media-links .fa-linkedin:hover, ul.media-links .fa-pinterest:hover, ul.media-links .fa-twitter:hover, ul.media-links .fa-whatsapp:hover, ul.media-links .fa-youtube:hover{background:unset !important}
label {display: inline-block;margin-bottom: 0rem;font-size: 11pt;}
.form-group {margin-bottom: 1rem;}
textarea{resize:none !important}

/*Search Button*/
.input-group .search-btn {display:block!important;position: absolute;right: 0px;}
.input-group .search-btn a {position: absolute !important;color: #212529;z-index: 99;right: 3px;top: 3px;background: transparent;height: 37px;padding: 2px 10px;display: block;text-decoration: none;border-radius: 0px !important;border: 0px !important;display: block !important;line-height: 28px;}
.input-group .search-btn a .fa, .input-group .search-btn a svg{ line-height:20px; font-weight:normal;}

@media (min-width:320px){
#EventSpeakers .accordion {border:0px !important;}
#EventSpeakers .accordion-item {border-radius: 0rem !important; border: 0px solid #dee2e6 !important; background: transparent;}
#EventSpeakers .accordion-button::after {display: none;}
#EventSpeakers .accordion-button:not(.collapsed) {background: transparent !important; color: #fff; box-shadow: none;}
#EventSpeakers .accordion-button {display: block; width: auto; margin: 0px auto; padding: 0px;;}
#EventSpeakers .accordion-body {background: transparent !important;}
}

#EventSpeakers button a::after {content:"View More";min-width:55px !important; opacity: 1 !important;}
#EventSpeakers .accordion-button:not(.collapsed) a::after {content:"View Less";min-width:55px !important; opacity: 1 !important;}

@media (max-width:767px){
#EventSchedule .nav-tabs {overflow-x: scroll; overflow-y:hidden; flex-wrap: nowrap;}
#EventSchedule .nav-tabs .nav-link {white-space: nowrap;}
}


#readmorecollapse #collapserm.collapsing {
  height: 7rem;
}

#readmorecollapse #collapserm.collapse:not(.show) {
  display: block;
  height: 7rem;
  overflow: hidden;
}

#readmorecollapse #readmorebutton button.collapsed::after {
  content: 'Afficher toutes les infos';
}

#readmorecollapse #readmorebutton button:not(.collapsed)::after {
  content: 'Cacher les infos';
}

/*Event Badge*/
.bg-everight {background: #f0f4ee;}
.event-category {
  font-size: 13px;
  color: #fff;
  background-color: #709654;
  font-weight: 500;
  letter-spacing: 0.2px;
  display: inline-block;
  padding: 0 28px;
  min-height: 37px;
  position: absolute;
  left: -18px;
  top: 20px;
  border-radius: 7px 7px 7px 0px;
  text-transform: uppercase;
  line-height: 37px;
}
.event-category-eve {
  font-size: 13px;
  color: #fff;
  background-color: #709654;
  font-weight: 500;
  letter-spacing: 0.2px;
  display: inline-block;
  padding: 0 28px;
  min-height: 37px;
  position: absolute;
  left: -18px;
  top: 20px;
  border-radius: 7px 7px 7px 0px;
  text-transform: uppercase;
  line-height: 37px;
}
.event-category::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 18px 10px 0;
  border-color: transparent #63994c transparent transparent;
  left: 0;
  top: 100%;
}
.event-category-eve::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 18px 10px 0;
  border-color: transparent #63994c transparent transparent;
  left: 0;
  top: 100%;
}

.nav-tabs .nav-item .nav-link {background:#f0f4ee !important; color:#709654 !important ;}
.nav-tabs .nav-item .nav-link.active {background:#709654 !important; color:#fff !important;  font-weight: bold;}
.nav-tabs {padding: 0px 15px; border:0px;}
.nav-tabs .nav-link {border:0px;}
.nav-tabs .nav-item {padding: 0px 2px;}
.tab-content .accordion-button {padding: 10px;}

@media (min-width:768px){
.nav-tabs {padding: 0px 25px; border:0px;}
.nav-tabs .nav-link {padding: 10px 25px; border:0px;}
.nav-tabs .nav-link:hover {border-color: #fff;}
.tab-content .accordion-button {padding: 15px;}
}

.tab-content .accordion-body {background: #fff !important;}

.sticky {position: sticky;top: 130px;}

/*
 * CKEditor 5 (v40.0.0) content styles.
 * Generated on Thu, 09 Nov 2023 05:51:32 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

 :root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-selector-caption-background: hsl(0, 0%, 97%);
  --ck-color-selector-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-selector-caption-text);
  background-color: var(--ck-color-selector-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
  table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  overflow-wrap: break-word;
  position: relative;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  position: relative;
  margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content[dir=rtl] .todo-list .todo-list__label > input {
left: 0;
margin-right: 0;
right: -25px;
margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
  position: absolute;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input,
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
  cursor: pointer;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input:hover::before, .ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input:hover::before {
  box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content[dir=rtl] .todo-list .todo-list__label > span[contenteditable=false] > input {
  left: 0;
  margin-right: 0;
  right: -25px;
  margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
  position: absolute;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
  height: auto;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
  list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
  list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
  list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
  list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
  list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
  list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
  list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
      padding: 0;
  }
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
      display: none;
  }
}

.fsDiv {width:auto !important;}
.fsDiv p {width:auto !important; margin-top: 5px;}

.filterheader button.accordion-button {background: transparent !important; color: #000; padding: 10px 0px; font-weight: 500;}
.filterheader .accordion-button::after {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>');
}
.filterheader .accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/></svg>');
  transform: var(--bs-accordion-btn-icon-transform);
}
.filterheader .accordion-header {background: #f0f4ee;}
.filterheader.accordion-item {border-radius: .75rem !important; border: 0px solid #dee2e6 !important;}

.publications a {color:#004f9f; text-decoration: underline;}
.publications a:hover {color: inherit; text-decoration: none; display: block;}

.projectslist a {color: inherit; text-decoration: underline;}
.projectslist a:hover {color: inherit; text-decoration: none;}

.pagination .page-item a {text-decoration: none;}
.pagination .page-item a:hover {text-decoration: none; background: #f0f4ee;}
.pagination .active>.page-link {text-decoration: none; background: #709654 !important; color:#fff; border:1px solid #709654}
.pagination .page-link:focus {box-shadow: none; color: inherit; background: #f0f4ee;}
.filter .accordion {overflow: auto !important;}

#offcanvas-filtermenu {background: #f0f4ee;}
@media (max-width:1200px) {
.visual-inside-big1 {margin-bottom: 0px;}
.filter .accordion, #offcanvas-filtermenu .accordion {border: 0px !important; overflow: auto !important;}
.filterheader.accordion-item {border-radius: .75rem !important; border: 0px solid #dee2e6 !important; background: none;}
}

.bg-newssection {background: rgb(227,233,223); }
.bg-newssection-news {background: rgb(227,233,223); }
@media (min-width:1200px) {
.bg-newssection {background: rgb(227,233,223); background: linear-gradient(270deg, rgba(227,233,223,1) 31%, rgba(240,244,238,1) 31%);}
.bg-newssection-news {background: rgb(227,233,223); background: linear-gradient(270deg, rgba(227,233,223,1) 25.4%, rgba(240,244,238,1) 25.4%);}
}

.swiper-button-next, .swiper-button-prev {top:30px}
.swiper-button-prev {right:35px; left: auto;}
.swiper-button-next::after, .swiper-button-prev::after {font-size: 25px; color:#709654;}

@media (min-width:1200px) {
.visual-container {padding-top: 75px;}
}


.dropdown:hover >, .dropend:hover > {display: block;margin-top: -0.125em;}
.dropend > .dropdown-menu {display: none;}
.dropend > .dropdown-menu.show {display: block !important;}

.dropend .nav-link {padding: 0.4rem 0.5rem !important; color:#4090c4; font-weight: 500;}

@media (min-width:1200px) {
  .dropdown:hover >, .dropend:hover > {display: block;margin-top: -0.125em;}
.dropend > .dropdown-menu {display: none !important;}
.dropend:hover > .dropdown-menu {display: block !important;}
.dropend .nav-link {padding: 0.4rem 1.5rem !important; color:#4090c4; font-weight: 500;}
.dropend .nav-link::before {display: none;}
.dropend.dropdown-item .dropdown-toggle::after {
    margin-top: 0.75rem;
    float: right;
}


}

@media screen and (min-width) {
  .dropend:hover > {position: absolute;top: 0;left: 100%;margin-top: 0em;}}
  @media (max-width:1200px) {
  .dropdown-menu {background: #f5f5f5;}
  .dropend.dropdown-item .dropdown-toggle::after {
    display: inline-block;
    margin-left: .5em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid !important;
      border-right: .3em solid transparent !important;
      border-bottom: 0 !important;
      border-left: .3em solid transparent !important;
  }
  .dropdown-menu .bg-white {background: transparent !important;}
}  